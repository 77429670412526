'use client'

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useAtomValue } from 'jotai'
import { appBannerAtom } from '@/stores/appBanner'
import { isWebview } from '@/utils'
import { isIOS } from '@/utils/mobileDetect'
import AppDownloadBanner from '../AppDownloadBanner'

const APP_BANNER_HEIGHT = 60
export const WRAPPER_ID = 'app-banner-wrapper'

/**
 * 앱 다운로드 배너와 헤더를 포함한 영역으로, children으로 전달된 헤더는 항상 sticky하게 유지되고
 * 앱 다운로드 배너는 스크롤에 따라 노출 및 숨김 처리된다.
 * @param children 앱 다운로드 배너 아래에 위치할 헤더 컴포넌트
 */
const AppDownloadBannerWrapper = ({ children }: PropsWithChildren) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [translateY, setTranslateY] = useState(0)
  const isAppBannerOpen = useAtomValue(appBannerAtom)

  // 스크롤 함에 따라, translateY 값을 계산 및 업데이트
  const handleScroll = useCallback(() => {
    if (!divRef.current) return
    const currentScroll = window.scrollY
    const currentTranslateY = parseFloat(
      getComputedStyle(divRef.current).transform.split(',')[5],
    )

    if (lastScrollY <= 0 || currentScroll <= 0) {
      setTranslateY(0)
    }
    // 스크롤을 내릴 때
    else if (currentScroll > lastScrollY)
      setTranslateY(
        Math.max(
          -APP_BANNER_HEIGHT,
          currentTranslateY - (currentScroll - lastScrollY),
        ),
      )
    // 스크롤을 올릴 때
    else if (currentScroll < lastScrollY) {
      setTranslateY(
        Math.min(0, currentTranslateY + (lastScrollY - currentScroll)),
      )
    }

    setLastScrollY(currentScroll)
  }, [lastScrollY])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  // 앱 다운로드 배너를 노출하지 않을 때는 그냥 children만 반환
  if (isWebview() || isIOS() || !isAppBannerOpen) return <>{children}</>

  return (
    <div
      id={WRAPPER_ID}
      ref={divRef}
      className="sticky z-20 top-0"
      style={{ transform: `translateY(${translateY}px)` }}
    >
      <AppDownloadBanner />
      {children}
    </div>
  )
}

export default AppDownloadBannerWrapper
