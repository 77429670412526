import Image from 'next/image'
import { S3_ICON_URL } from '@/config'
import { CancerType } from '@/consts'
// TODO: '@/consts'에서 한 번에 불러오면 오류가 난다. 문제 해겶 필요.
import { URL_PATH } from '@/consts/urls'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { QuickLinkProps } from './QuickLink'

const CancerIcon = ({ name }: { name: CancerType }) => {
  return (
    <Image
      width={28}
      height={28}
      src={`${S3_ICON_URL}/${name}_filled.svg`}
      alt=""
    />
  )
}

export const QUICK_LINKS: QuickLinkProps[] = [
  {
    icon: (
      <MaterialSymbol
        name="home_health_fill"
        size={28}
        className="fill-aqua-500"
      />
    ),
    title: '병원정보',
    description: `암 치료 병원\n찾아보기`,
    href: URL_PATH.HospitalsMain,
  },
  {
    icon: (
      <MaterialSymbol
        name="contact_support_fill"
        size={28}
        className="fill-lavendar-500"
      />
    ),
    title: '질문과 답변',
    description: `환자 · 보호자\n질문 모아보기`,
    href: URL_PATH.Boards,
  },
  {
    icon: <CancerIcon name="breast" />,
    title: '주제별 암 정보',
    description: `유방암 정보\n확인하기`,
    href: URL_PATH.GuidesMainBreast,
  },
  {
    icon: <CancerIcon name="thyroid" />,
    title: '주제별 암 정보',
    description: `갑상선암 정보\n확인하기`,
    href: URL_PATH.GuidesMainThyroid,
  },
  {
    icon: <CancerIcon name="lung" />,
    title: '주제별 암 정보',
    description: `폐암 정보\n확인하기`,
    href: URL_PATH.GuidesMainLung,
  },
  {
    icon: <CancerIcon name="stomach" />,
    title: '주제별 암 정보',
    description: `위암 정보\n확인하기`,
    href: URL_PATH.GuidesMainStomach,
  },
  {
    icon: <CancerIcon name="colon" />,
    title: '주제별 암 정보',
    description: `대장암 정보\n확인하기`,
    href: URL_PATH.GuidesMainColon,
  },
]
