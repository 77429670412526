import { PropsWithChildren } from 'react'
import useBottomSheetHeight from '@/hooks/useModal/useBottomSheetHeight'
import ModalComponent from '@/v1/Modal/index'
import Overlay from '@/v1/Modal/Overlay'
import { bottomSheetStyle } from '@/v1/Modal/styles'

export const BottomSheetModal = ({
  opened = false,
  children,
  height,
  closeable,
  onClose,
}: PropsWithChildren<{
  opened: boolean
  height?: number | string
  closeable?: boolean
  onClose?: () => void
}>) => {
  const { getBottomSheetHeight } = useBottomSheetHeight()

  if (!opened) return null

  return (
    <ModalComponent>
      <Overlay onClose={onClose} closeable={closeable} />
      <div
        className={bottomSheetStyle}
        role="dialog"
        aria-labelledby="bottomSheetTitle"
        style={{ height: height || getBottomSheetHeight(0) }}
      >
        {children}
      </div>
    </ModalComponent>
  )
}

export default BottomSheetModal
