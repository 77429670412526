import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { useAtomValue, useSetAtom } from 'jotai'
import { ANDROID_APP_STORE, MaxWidthSize } from '@/consts'
import { appBannerAtom } from '@/stores'
import { isWebview } from '@/utils'
import { isIOS } from '@/utils/mobileDetect'
import MaterialSymbol from '@/v1/MaterialSymbol'

const AppDownloadBanner = () => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const setIsAppBannerOpen = useSetAtom(appBannerAtom)
  const pathname = usePathname()
  const isAppBannerOpen = useAtomValue(appBannerAtom)

  const handleClick = () => {
    window.location.href = `lunitcare:/${pathname ?? '/'}`

    timerRef.current = setTimeout(() => {
      window.open(ANDROID_APP_STORE, '_blank', 'noopener,noreferrer')
    }, 2000)
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      clearTimeout(timerRef.current)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  if (isWebview() || isIOS() || !isAppBannerOpen) return null

  return (
    <div
      className={clsx(
        'bg-grey-100',
        'h-[60px]',
        'flex',
        'justify-center',
        'items-center',
        'pl-md pr-[17px]',
      )}
    >
      <div
        className={clsx(
          MaxWidthSize.md,
          'flex',
          'justify-between',
          'items-center',
          'w-full',
        )}
      >
        <p className="self-center prose-p2 break-keep">
          <b>프리미엄 1:1 암 관리, 루닛케어</b>
        </p>
        <div className="flex">
          <button
            data-ga="topbanner_app"
            onClick={handleClick}
            className={clsx(
              'bg-aqua-500',
              'rounded-[8px]',
              'h-9',
              'px-3',
              'flex',
              'items-center',
              'mr-3',
              'min-w-[64px]',
            )}
          >
            <p className="text-white prose-p3">앱 열기</p>
          </button>
          <button onClick={() => setIsAppBannerOpen(false)}>
            <MaterialSymbol size={20} name="close" className="fill-grey-600" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default AppDownloadBanner
